<script lang="ts" setup>

</script>

<template>
    <div class="min-h-screen flex flex-col justify-between">
        <!-- <LoaderLottieAnimation /> --> <!-- Анимация загрузки (Lottie) -->
         <div class="main_row">
            <LayoutsHeader />
            <NuxtPage />
        </div>
        <LayoutsFooter />
        <CustomUiBottomBar />
    </div>
</template>